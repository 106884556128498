module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-head/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"elinesdesign","accessToken":"MC5ZZTh4ZUJFQUFDRUF4Z1di.77-9R--_vRwqQO-_ve-_vV3vv73vv73vv70TPO-_ve-_vSLvv71CSRxr77-9We-_vVLvv73vv70077-977-977-9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Elines Design","short_name":"Elines Design","icons":[{"src":"/icons/favicon.ico","type":"image/x-icon","sizes":"16x16 32x32"},{"src":"/icons/icon-192.png","type":"image/png","sizes":"192x192"},{"src":"/icons/icon-512.png","type":"image/png","sizes":"512x512"},{"src":"/icons/icon-192-maskable.png","type":"image/png","sizes":"192x192","purpose":"maskable"},{"src":"/icons/icon-512-maskable.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
