import * as React from 'react'
import {
  PrismicPreviewProvider,
} from 'gatsby-plugin-prismic-previews'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)